import { Injectable } from '@angular/core';
import { Marca } from '../models/marca';
import { Estado } from '../models/estado';
import { HttpService } from './http.service';
import { Metadado } from '../models/metadado';

@Injectable({
  providedIn: 'root'
})
export class MarcaService {
  marcas : Marca[] = [];
  httpService: HttpService;

  filtrado: Marca[] = [];

  constructor(httpService : HttpService) {
    this.httpService = httpService;
  }

  add(marca: Marca) {
    this.marcas.push(marca);
  }

  update(marca: Marca) {
    this.marcas.forEach((m, index) => {
      if (m.id === marca.id) {
        this.marcas[index] = JSON.parse(JSON.stringify(marca));
      }
    });
    this.filtrado.forEach((m, index) => {
      if (m.id === marca.id) {
        this.filtrado[index] = JSON.parse(JSON.stringify(marca));
      }
    });

  }

  getAll(metadados: Metadado[]) : Promise<Marca[]> {
    return new Promise(
      (resolve, reject) => {
        this.httpService.get("v1/Marca/GetAll")
        .then((dt : Marca[]) => {
          this.processaMarcas(dt, metadados)
          resolve(this.filtrado);
        })
        .catch(error => reject(error))
      }
    );
  }

  getMarcasCompletas() {
    return this.marcas;
  }

  updateMarcas() {
    return this.filtrado;
  }

  processaMarcas(marcas: Marca[], metadados: Metadado[]) : Marca[] {
    this.filtrado = [];
    this.marcas = [];
    marcas.forEach(data => {
      this.filtrado.push(new Marca(data, metadados));
      this.marcas.push(new Marca(data, metadados));
    });
    this.insereInvisivel();

    return this.marcas;
  }

  delete(marca: Marca) {
    return new Promise(
      (resolve, reject) => {
        let method = 'v1/Marca/Delete';
        this.httpService
        .delete(method + '?id=' + marca.id)
        .then(dt => resolve(dt))
          .catch(error => reject(error));
      }
    );
  }

  save(marca: Marca) {
    let inclusao : boolean = !(marca.id);

    return new Promise(
      (resolve, reject) => {
        if (inclusao) {
           marca.id = undefined;
           this.httpService.post('v1/Marca/Add', marca).then(dt => resolve(dt)).catch(error => reject(error));
        } 
        else
          this.httpService.put('v1/Marca/Update', marca).then(dt => resolve(dt)).catch(error => reject(error));
      }
    );
  }

  insereInvisivel() {
    for (let k = 0; k < 5; k ++)
      this.filtrado.push(new Marca(null, null));
  }

  filtrar(estado: Estado) :void {
    let filter: string = estado.filtro;
    filter = this.retira_acentos(filter).toLowerCase();

    while (this.filtrado.length > 0)
      this.filtrado.pop();

    this.marcas.forEach(marca => {
      if (!marca.tags) marca.tags = [];
      if (
        filter == '' || 
        this.retira_acentos(marca.nome).toLowerCase().indexOf(filter) > -1 ||
        this.retira_acentos(marca.missao).toLowerCase().indexOf(filter) > -1 ||
        this.retira_acentos(marca.personalidade).toLowerCase().indexOf(filter) > -1 ||
        this.retira_acentos(marca.visao).toLowerCase().indexOf(filter) > -1 ||
        this.retira_acentos(marca.tags.map(x => x).join()).toLowerCase().indexOf(filter) > -1
        )
      {
        if (estado.filtrosSelecionados.length == 0) {
          this.filtrado.push(marca);
        } else {
          let encontrou : boolean = false;

          if (marca.metadados)
            estado.filtrosSelecionados.forEach(f => f.encontrou = false);
            if (marca.metadados)
              marca.metadados.forEach(m => {
                estado.filtrosSelecionados.forEach(f => {
                  if (m == f.id)
                    f.encontrou = true;
                });            
          });

          encontrou = true;
          estado.filtrosSelecionados.forEach(f => {
            if (!f.encontrou)
              encontrou = false;
          });

          if (encontrou) {
            this.filtrado.push(marca);
          }
        }
      }
    });

    estado.quantidadeItens = this.filtrado.length;

    this.insereInvisivel();
  }  

  // metadadoExibe(posicao): Metadado[] {
  //   return this.filtrado[posicao].marcasMetadado.filter(x => x.exibe);
  // }

  retira_acentos(str: string) : string 
  {
    if (!str) return '';

    let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

    let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let novastr : string = "";
    for(let i=0; i<str.length; i++) {
          let troca=false;
          for (let a=0; a<com_acento.length; a++) {
              if (str.substr(i,1)==com_acento.substr(a,1)) {
                  novastr+=sem_acento.substr(a,1);
                  troca=true;
                  break;
              }
          }
          if (troca==false) {
              novastr+=str.substr(i,1);
          }
    }
    return novastr;
  }
}
