  <app-header [estado]="estado" 
  (insertMarcaClick)="insertMarca()"
  (abreUsuariosClick)="abreUsuarios()"
  (abreMetadadosClick)="abreMetadados()"
  ></app-header>

  <app-filter-top [estado]="estado" (filtroAlterado)="filtrar($event)"></app-filter-top>
  
  <div id="div-body">
    <app-metadados 
    [estado]="estado" 
    [filtros]="metadados" class="app-metadados"
    [ngClass]="{'app-metadados-fechado': aberto}"
    (filterClicked)="incluiFiltro($event)" 
    (filtroaberto)="abertofechado($event)"></app-metadados>

    <app-header-grupo-marcas 
    [estado]="estado" 
    class="app-header-grupo-marcas"
    [ngClass]="{'app-header-grupo-marcas-fechado': aberto}"
    (filtroFoiRemovido)="removeFiltro($event)"></app-header-grupo-marcas>

    <app-grupo-marcas [ngClass]="{'grupoaberto': aberto, 'grupofechado': !aberto}" 
    [marcas]="marcas" 
    (detalheFoiAberto)="abrirDetalhe($event)" 
    [estado]="estado" ></app-grupo-marcas>
  </div>

  <app-detalhe-marca *ngIf="estado.detalheMarcaVisible" 
  [marcas]="marcas"
  [marcaId]="marcaId" 
  [modoEdicao]="modoEdicao"
  [metadados]="metadados"
  [estado]="estado"
  (detalheFoiFechado)="estado.detalheMarcaVisible=false; modoEdicao=false;">
  </app-detalhe-marca>

  <app-grid-usuarios *ngIf="estado.usuariosVisible"
  (usuarioFoiFechado)="fechaUsuarios()">
  </app-grid-usuarios>

  <app-form-metadados *ngIf="estado.metadadosVisible"
  (metadadoFoiFechado)="fechaMetados()"
  [metadados]="metadados"
  [marcas]="marcasCompletas"
  [estado]="estado"
  >
  </app-form-metadados>
