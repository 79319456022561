import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: OAuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
        let ret = false; 

        let usu = localStorage.getItem("usuario");
        if (usu)
          if (JSON.parse(usu)["token"])
            ret = true;

        return ret;
  }
}