<div id="div-background" (click)="fecharDetalhe()">
  <div id="botaofechar" (click)="fecharDetalhe()">
    <img src="assets/img/close-cinza.png">
  </div>
</div>

<div id="detalheMarca" *ngIf="marcas && marcas.length > 0">
  <div class="detalheLeft" *ngIf="modoEdicao">
    <app-form-marca [metadados]="metadados" 
    [marcaRecebida]="marcas[posicao]" 
    [marcas]="marcas"
    (positionchanged)="changePosition($event)"
    (canceled)="canceled($event)"
    [estado]="estado"
    ></app-form-marca>
  </div>
  
  <div class="detalheLeft" *ngIf="!modoEdicao">
    <div id="detalheMarca_Title"><span>{{ marcas[posicao].nome }}</span><button mat-raised-button color="link" type="button" 
      (click)="modoEdicao=true;" *ngIf="estado.administrador"
      class="btnEdicao">
      Editar
      </button></div>
      
    <div id="detalheMarca_Head">
      <div id="detalheMarca_Head_Baixar" *ngIf="marcas[posicao].linkKit">
        <img src="assets/img/file.png" />
        <div >Baixar Kit da Marca:</div>
      </div>
      <div id="detalheMarca_Head_Botoes" *ngIf="marcas[posicao].linkKit">
          <a [href]="marcas[posicao].linkKit" class="botao">DOWNLOAD</a>
      </div>
      
      <div id="detalheMarca_metadado" *ngFor="let m of metadadoService.metadadoExibe(marcaAtual); index as i">
        <ng-container *ngIf="i==0">
        <div class="linhacor" [style]="'background-color: ' + cores[m.nome]"></div>
        <div class="metadadoContainer">
          <div class="metadadotexto">{{m.pai}}</div>
          <div class="metadadovalor" [style]="'color: ' + cores[m.nome]">{{m.nome}}</div>
          <div class="logogbl"><img src="assets/img/logogbl.png" /></div>
        </div>
        <div class="linhacor" [style]="'background-color: ' + cores[m.nome]"></div>
        </ng-container>
      </div>
    </div>

    <div id="detalheMarca_Body">
      <div>
        <ng-container *ngFor="let m of metadadoService.metadadoExibe(marcaAtual); index as i">
          <ng-container *ngIf="i>0">
            <div *ngIf="(m.pai!=metadadoService.metadadoExibe(marcaAtual)[i-1].pai&&i>1)" class="linhacinza">
            </div>
          </ng-container>
          <ng-container *ngIf="i>0">
            <div class="titulo" *ngIf="m.pai!=metadadoService.metadadoExibe(marcaAtual)[i-1].pai">{{m.pai}}</div>
          </ng-container>
          <ng-container *ngIf="i>0">
            <span class="conteudo">{{m.nome}}</span>
            <ng-container *ngIf="i+1 < metadadoService.metadadoExibe(marcaAtual).length">
              <span class="conteudo" *ngIf="m.pai==metadadoService.metadadoExibe(marcaAtual)[i+1].pai">, </span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="i>0">
            <div *ngIf="metadadoService.metadadoExibe(marcaAtual).length == i+1" class="linhacinza">
            </div>
          </ng-container>
          
        </ng-container>
        <div class="titulo">Atributos Principais</div>
        <div class="conteudo">{{ marcas[posicao].atributosPrincipais }}</div>
        <div class="linhacinza"></div>
        <div class="titulo">Personalidade</div>
        <div class="conteudo">{{ marcas[posicao].personalidade }}</div>
        <div class="linhacinza"></div>
        <div class="titulo">Visão</div>
        <div class="conteudo">{{ marcas[posicao].visao }}</div>
        <div class="linhacinza"></div>
        <div class="titulo">Missão</div>
        <div class="conteudo">{{ marcas[posicao].missao }}</div>
      </div>
    </div>
  </div>

  <div id="detalheRight">
    <div id="detalheRight-borda">
      <div class="botaonavegar" (click)="anterior()" *ngIf="marcaAtual.posicaoArquivo>0"><</div>
    </div>
    <div id="detalheRight-centro">
      <app-marca [marca]="marcas[posicao]" ></app-marca>
    </div>
    <div id="detalheRight-borda">
      <div class="botaonavegar" (click)="proximo()" *ngIf="marcaAtual.posicaoArquivo < capas().length - 1">></div>
    </div>
  </div>
  <img src="cacheImagem" style="display: none">
</div>


