import { Injectable } from '@angular/core';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpService: HttpService) { }

  login(authorizationcode: string, PKCI_verifier: string) {
    return new Promise(
      (resolve, reject) => {
        this.httpService.get("v1/Account/login/" +  PKCI_verifier  + "/" + authorizationcode)
        .then((data : any) => {
          resolve(data);
        })
        .catch(error => reject(error))
      }

    );
  }
}
