  <div class="div-background" [ngClass]="{'div-background-visivel': aberto}">
  </div>

    <div class="filtro-link-container">
      <div class="filtro-link" (click)="abrirfechar()">
        <div class="filtro-title">Filtros</div>
        <div class="caracter-menor">
          <span *ngIf="aberto">
            <img src="assets/img/menor-cinza.png" width="8px"/>
          </span><span *ngIf="!aberto">
            <img src="assets/img/maior-cinza.png" width="8px"/>
          </span>
        </div>
      </div>
      <div class="filtro-link-imagem" (click)="abrirfechar()">
        <div class="filtro-link-mobile" *ngIf="aberto">
          <div class="filtro-title">Filtros</div>
          <div class="caracter-menor">
            <span *ngIf="aberto">
              <img src="assets/img/menor-cinza.png" />
            </span><span *ngIf="!aberto">
              <img src="assets/img/maior-cinza.png" />
            </span>
          </div>
        </div>
        <img src="assets/img/btn-metadado.png" width="94px" *ngIf="!aberto">
      </div>
    </div>
    <div class="filtro-body coluna-esquerda" *ngIf="aberto">
      <div class="filtro-body-item" *ngFor="let filtro of filtros">
        <div class="filtro-body-item-head" (click)="selecionaFiltro(filtro)">
          <div class="filtro-body-item-title">{{ filtro.nome }}</div>
          <div class="filtro-body-item-plus">
            <span *ngIf="!filtro.selecionado">
              <img src="assets/img/mais-cinza.png" width="10px"/>
            </span>
            <span *ngIf="filtro.selecionado">
              <img src="assets/img/close-cinza.png" width="10px"/>
            </span></div>
        </div>        
        <div *ngIf="filtro.selecionado" class="filtro-body-item-bottom">
          <div (click)="seleciona(filho)" 
          [ngClass]="{'selecionado': filho.selecionado}"
          class="filtro-body-item-child selecionado" 
          *ngFor="let filho of filtro.metadados">
            <ng-container>
            {{ filho.nome }}
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  