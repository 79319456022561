<div id="principal">
<mat-toolbar class="titulo">
  <span class="fill-remaining-space">Cadastro de Marcas</span>
</mat-toolbar>
  <div class="container" novalidate> 
    <form class="form">
      <ng-container *ngFor="let campo of camposMarcas">
        <mat-error *ngIf="!marca[campo['name']] && campo['touched'] && !campo['nullable']">Preencha {{ campo['title'] }}</mat-error>
        <mat-form-field class="form-element" appearance="fill" required>
          <mat-label>{{ campo['title'] }} <span *ngIf="!campo['nullable']">*</span></mat-label>
          <input *ngIf="campo.type=='input'" matInput placeholder="{{ campo['title'] }}" 
          name="{{ campo['name'] }}" [(ngModel)]="marca[campo['name']]" 
          (keyup)="campo['touched']=true" maxlength="50">
          <textarea *ngIf="campo.type=='textarea'" matInput placeholder="{{ campo['title'] }} " name="{{ campo['name'] }}" [(ngModel)]="marca[campo['name']]" (keyup)="campo['touched']=true" >
          </textarea>
        </mat-form-field>
        

      </ng-container>
      <ng-container *ngFor="let metadado of metadados">
        <div class="container-metadado">
          <mat-label class="form-element">{{ metadado['nome'] }}</mat-label>
            <mat-grid-list cols="3" rowHeight="4:1" *ngIf="metadado['aceitaMultiplo']">
              <mat-grid-tile *ngFor="let filho of metadado['metadados']" class="container-filhos">
                <mat-checkbox class="form-element"
                  (change)="selecionaMetadado($event, filho, metadado)"
                  value="{{ filho['id'] }}"
                  [checked]="filhoSelecionado(filho)">
                  {{ filho['nome'] }}
                </mat-checkbox>
              </mat-grid-tile>
            </mat-grid-list>       
          <mat-radio-group
            *ngIf="!metadado['aceitaMultiplo']"
            class="container-filhos"
            [(ngModel)]="metadado.selecionados[0]"
            [ngModelOptions]="{standalone: true}">
            <mat-grid-list cols="3" rowHeight="4:1">
              <mat-grid-tile *ngFor="let filho of metadado['metadados']" class="container-filhos">
                <mat-radio-button class="form-element"
                  value="{{ filho['id'] }}"
                  >
                  {{ filho['nome'] }}
                </mat-radio-button>
              </mat-grid-tile>
            </mat-grid-list>            
          </mat-radio-group>
        </div>
      </ng-container>
      <div class="bloco">
        <mat-label class="form-element">TAGS</mat-label>
        <div *ngFor="let tag of marca.tags; let i = index" class="container-tags">
          <mat-label class="form-element">{{ tag }}</mat-label>
          <mat-icon aria-hidden="false" aria-label="Excluir" (click)="excluirTag(i)" class="icon">delete</mat-icon>
        </div>
        <mat-form-field class="form-element">          
          <input matInput placeholder="Incluir TAG" name="newTag" [(ngModel)]="newTag" class="campoTag" (keyup)="VerificaEnter($event)">
          <mat-icon aria-hidden="false" aria-label="Incluir" (click)="incluirTag()" class="icon">add</mat-icon>
        </mat-form-field>
      </div>
      <ng-container *ngIf="marca.id">
        <mat-error *ngIf="erroKit">{{ erroKit }}</mat-error>
        <mat-label class="form-element">KIT</mat-label>
        <div *ngIf="kit()[0];else semKit">
              <mat-label>Baixar Kit da Marca</mat-label>
              <a mat-raised-button color="alert" type="button" class="button"
               [href]="kit()[0].link" *ngIf="kit()[0].link">DOWNLOAD</a>
              <a mat-raised-button color="alert" type="button" class="button" (click)="excluirKit()">EXCLUIR KIT</a>
        </div>
        <ng-template #semKit>
            <p><mat-label class="form-element">Kit ainda não cadastrado, selecione um arquivo abaixo</mat-label></p>
            <p><input #fileKit type="file" (change)="changeKit($event.target.files)" accept="*.zip"></p>
        </ng-template>
        <p *ngIf="loaderKit">
          AGUARDE!! Essa operação poderá levar alguns minutos.
          <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
        </p>
        <div class="bloco">
          <mat-error *ngIf="erroCapa">{{ erroCapa }}</mat-error>
          <mat-label class="form-element">Capas (Selecione a capa padrão)</mat-label>
        </div>        
        <div  class="container-tags">
          <mat-radio-group
            class="capaRadioGroup"
            [(ngModel)]="capaSelecionada"
            [ngModelOptions]="{standalone: true}">
                <ng-container *ngFor="let capa of capas(); let i = index">
                  <mat-radio-button
                    class="capaRadioButton"
                    value="{{ capa.id }}"
                    >
                    {{ capa.nome }}
                  </mat-radio-button>
                  <mat-icon aria-hidden="false" aria-label="Excluir" (click)="excluirCapa(i)" class="icon deleteRadioButton">delete</mat-icon>
                </ng-container>
                
          </mat-radio-group>
          
        </div>
        <p><mat-label class="form-element">Selecine uma capa padrão jpg ou png quadrada</mat-label></p>
        <p><input #fileCapa type="file" (change)="insertCapa($event.target.files)" accept="*.jpg, *.png"></p>
        <p><mat-progress-bar color="primary" mode="indeterminate" *ngIf="loaderCapa"></mat-progress-bar></p>

      </ng-container>

      <ng-container *ngFor="let campo of camposMarcas">
        <mat-error *ngIf="!marca[campo['name']] && !campo['nullable']">Preencha {{ campo['title'] }}</mat-error>
      </ng-container>
      <mat-error *ngIf="mensagemErro!=''">{{mensagemErro}}</mat-error>
      <mat-error *ngIf="newTag!=''">Finalize a inclusão da TAG</mat-error>
      
      

      <div class="form-element buttonContainer">
        <button mat-raised-button color="warn" type="button" class="button" (click)="openDialog()" *ngIf="marca['id']">Excluir</button>
        <button mat-raised-button color="alert" type="button" class="button" (click)="fechar()">Cancelar</button>
        <button mat-raised-button color="primary" type="button" class="button" [disabled]="invalido()&&!erroKit&&!erroCapa" (click)="onSubmit()">Salvar</button>
      </div>
    </form>
  </div>

</div>