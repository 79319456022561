<div id="grupo-marcas-header">
    <div id="grupo-marcas-filtros-container"
        [ngClass]="{'grupo-marcas-filtros-container-fechado': estado.filtroaberto}">
        <div class="grupo-marcas-filtros" *ngFor="let filtro of estado.filtrosSelecionados">
            {{ filtro.nome }}
            <img (click)="remove(filtro)" src="assets/img/close.png" /> | 
        </div>
        <div class="grupo-marcas-filtros-resumo">
            <span *ngIf="!temFiltro()">Ordem alfabética | </span>
            <span *ngIf="temFiltro()">{{ estado.quantidadeItens }} resultado<span *ngIf="estado.quantidadeItens>1">s</span> | </span>
        </div>
    </div>
    <div id="grupo-marcas-filtros-icon-container" 
        [ngClass]="{'grupo-marcas-filtros-icon-container-filtrofechado': estado.filtroaberto}">
        <div id="grupo-marcas-header-grid-detalhe">
            <img *ngIf="!estado.gridDetalheSelecionado" src="assets/img/grid-detalhe.png" (click)="selecionaGridDetalhe(true)">
            <img *ngIf="estado.gridDetalheSelecionado" src="assets/img/grid-detalhe-selecionado.png">
        </div>
        <div id="grupo-marcas-header-grid">
            <img *ngIf="estado.gridDetalheSelecionado" src="assets/img/grid.png" (click)="selecionaGridDetalhe(false)">
            <img *ngIf="!estado.gridDetalheSelecionado" src="assets/img/grid-selecionado.png">
        </div>        
    </div>
</div>