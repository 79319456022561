<div (click)="fecharDetalhe()" id="div-background">
    <div (click)="fecharDetalhe()" id="botaofechar">
        
    </div>
</div>

<div id="gridMetadados">
    <div class="titulo">
        Cadastro de Metadados
    </div>
    <a mat-raised-button color="alert" type="button" class="button" (click)="novoMetadado()" *ngIf="!modoInsert&&!modoEdit">Incluir</a>

    <div *ngIf="modoInsert || modoEdit">
        <mat-error *ngIf="!metadadoTemp.nome && nomeFoiEditado">Preencha o Nome</mat-error>
                
        <mat-form-field appearance="fill" class="form-element campoNome" required>
            <mat-label>Nome<span>*</span></mat-label>
            <input [(ngModel)]="metadadoTemp.nome"
                    matInput maxlength="50"
                    name="nome"
                    id="nome"
                    (keyup)="nomeFoiEditado=true;"
                    placeholder="Nome">
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-element campoOrdem">
        <mat-label>Ordem</mat-label>
        <input [(ngModel)]="metadadoTemp.ordemExibicao"
                matInput maxlength="3"
                name="ordemExibicao"
                placeholder="Ordem"
                type="number">
        </mat-form-field>

        <mat-checkbox class="form-element campoExibe"
        [(ngModel)]="metadadoTemp.exibe">
            Exibe no detalhe da Marca
        </mat-checkbox>

        <mat-checkbox class="form-element campoMultiplo"
        [(ngModel)]="metadadoTemp.aceitaMultiplo">
            Aceita múltiplas opções
        </mat-checkbox>

        <br />
        <div class="tituloOpcoes">Opções</div>
        <div class="containerFilhos">
            <div *ngFor="let filho of metadadoTemp.metadados; let i = index" class="filtro-body-item-child">
                <mat-label class="form-element">{{ filho.nome }}</mat-label>
                <mat-icon (click)="excluirFilho(metadadoTemp, filho)"
                        aria-hidden="false"
                        aria-label="Excluir"
                        class="icon">delete
                </mat-icon>
            </div>
        </div>
        <mat-form-field class="form-element fieldOpcao">
            <input (keyup)="VerificaEnter($event, metadadoTemp)"
                [(ngModel)]="nomeNovoFilho"
                class="campoMetadadoFilho"
                matInput
                name="nomeNovoFilho"
                placeholder="Incluir opção">
            <mat-icon (click)="incluiFilho(metadadoTemp)"
                    aria-hidden="false"
                    aria-label="Incluir"
                    class="icon">add
            </mat-icon>
        </mat-form-field>
        <span class="erro" *ngIf="nomeNovoFilho">Finalize a inclusão da opção para continuar</span>
        <br />
        <a mat-raised-button color="alert" type="button" 
        class="button" (click)="cancela()">Cancelar</a>

        <a mat-raised-button color="primary" type="button" 
        class="button" (click)="salva()"
        [disabled]="!metadadoTemp.nome || nomeNovoFilho!= ''">Salvar</a>
        
    </div>

    <span class="erro">
    {{ erro }}
    </span>

    <div class="containertabela" *ngIf="!modoInsert && !modoEdit">
        <table class="tabelaMetadado mat-table cdk-table mat-elevation-z8">
            <thead>
                <tr class="titulotabela mat-header-row cdk-header-row ng-star-inserted">
                    <th class="colunaNome">Nome</th>
                    <th class="colunaOrdem">Ordem</th>
                    <th class="colunaExibe">Exibe no detalhe</th>
                    <th class="colunaMultiplo">Opções Múltiplas</th>
                    <th class="colunaAcoes">Ações</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let pai of metadados">
                    <tr class="mat-row cdk-row ng-star-inserted">
                        <td class="colunaNome celula mat-cell cdk-cell cdk-column-position mat-column-position ng-star-inserted">
                            <div class="ul">
                                <div class="content">
                                    {{ pai.nome }}
                                </div>
                            </div>
                        </td>
                        <td class="colunaOrdem celula mat-cell cdk-cell cdk-column-position mat-column-position ng-star-inserted">
                            {{ pai.ordemExibicao }}
                        </td>
                        <td class="colunaExibe celula mat-cell cdk-cell cdk-column-position mat-column-position ng-star-inserted">
                            <ng-container *ngIf="pai.exibe else naoExibe">
                                Sim
                            </ng-container>
                            <ng-template #naoExibe>
                                Não
                            </ng-template>
                        </td>
                        <td class="colunaMultiplo celula mat-cell cdk-cell cdk-column-position mat-column-position ng-star-inserted">
                            <ng-container *ngIf="pai.aceitaMultiplo else naoAceitaMultiplo">
                                Sim
                            </ng-container>
                            <ng-template #naoAceitaMultiplo>
                                Não
                            </ng-template>
                        </td>
                        <td class="colunaAcoes celula mat-cell cdk-cell cdk-column-position mat-column-position ng-star-inserted">
                            <ng-container>
                                <mat-icon (click)="abreEdit(pai)"
                                        aria-hidden="false"
                                        aria-label="Editar"
                                        class="icon">edit
                                </mat-icon>
                                <mat-icon (click)="excluiPai(pai)"
                                        aria-hidden="false"
                                        aria-label="Editar"
                                        class="icon">delete
                                </mat-icon>
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
