import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { Estado } from 'src/app/models/estado';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private oauthService: OAuthService, private router: Router) { }

  ngOnInit(): void {
  }

  menu: boolean = false;
  @Input() estado : Estado;
  @Output() insertMarcaClick = new EventEmitter();
  @Output() abreUsuariosClick = new EventEmitter();
  @Output() abreMetadadosClick = new EventEmitter();
  

  public logoff() {
    this.oauthService.logOut();
    this.router.navigate(["logout"]);
  }

  insertMarca() {
    this.insertMarcaClick.emit('');
  }

  abreUsuarios() {
    this.abreUsuariosClick.emit('');
  }

  abreMetadados() {
    this.abreMetadadosClick.emit('');
  }
}
