import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Metadado } from 'src/app/models/metadado';
import { Estado } from 'src/app/models/estado';

@Component({
  selector: 'app-header-grupo-marcas',
  templateUrl: './header-grupo-marcas.component.html',
  styleUrls: ['./header-grupo-marcas.component.scss']
})
export class HeaderGrupoMarcasComponent implements OnInit {

  @Input() estado : Estado;
  @Output() filtroFoiRemovido = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  selecionaGridDetalhe(opcao) {
    this.estado.gridDetalheSelecionado=opcao;
  }

  temFiltro() {
    return this.estado.filtrosSelecionados.length > 0 || this.estado.filtro != '';
  }

  remove(filtro) {
    this.filtroFoiRemovido.emit(filtro);

    let newFiltros : Metadado[] = [];
    this.estado.filtrosSelecionados.forEach(element => {
      if (element.id != filtro.id) {
        newFiltros.push(element);
      }
    });
    while(this.estado.filtrosSelecionados.length > 0) {
      this.estado.filtrosSelecionados.pop();
    }
    newFiltros.forEach(element => {
      this.estado.filtrosSelecionados.push(element);
    });
    
  }

}
