<div id="principal">
    <div id="login">
        <div class="card">
            <div class="tituloEntrar">
                <span class="tituloEntrarTexto">ENTRAR</span>
            </div>
            <div class="divCorpo">

                <button class="btnEntrar" *ngIf="!erro" color="primary">AGUARDE</button>

                <button class="btnEntrar" *ngIf="erro" (click)="tente()" color="primary">Clique para tentar novamente</button>

                <span class="erro" *ngIf="erro">
                    Houve algum erro ao tentar autenticar. Tente novamente clicando no botão acima. Caso o erro persista informe os administradores do portal.
                </span>
            </div>
            
            
        </div>
    </div>
</div>