import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8',
  });
  private token : string;

  constructor(private http: HttpClient) { 
    
  }

  setToken(token: string) {
    this.token = token;
    this.httpHeaders = this.httpHeaders.set('authorization', `Bearer ${token}`);
  }

  get(metodo: string) : Promise<any> {
    let url : string = environment.apiUrl + metodo;
    return new Promise(
      (resolve, reject) => {
        this.http.get(url, {
          headers: this.httpHeaders
        }).subscribe(data => resolve(data),
          error => {
            reject(error);
          },
          () => {
          });
      }
    );
  }

  put(metodo: string, body: any) {
    let url : string = environment.apiUrl + metodo;
    return new Promise((resolve, reject) => {
      this.http.put(url, body, {
        headers: this.httpHeaders
      }).subscribe(
        val => {
          resolve(val);
        },
        response => {
          reject(response);
        },
        () => {

        }
      );
    });
  }

  post(metodo: string, body: any, upload: boolean = false) {
    let url : string = environment.apiUrl + metodo;
    let headers : HttpHeaders;
    if (upload)
    {
      headers = new HttpHeaders();
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
      });
    }
    headers = headers.set('authorization', `Bearer ${this.token}`);
    return new Promise((resolve, reject) => {
      this.http.post(url, body, {
        headers: headers
      }).subscribe(
        val => {
          resolve(val);
        },
        response => {
          reject(response);
        },
        () => {

        }
      );
    });
  }

  delete(metodo: string) {
    let url : string = environment.apiUrl + metodo;
    return new Promise((resolve, reject) => {
      this.http.delete(url, {
        headers: this.httpHeaders
      })
      .subscribe(
          (val) => {
            resolve(val);
          },
          response => {
            reject(response);
          },
          () => {
          });
    });
  }

  public formataData(valordata) : string {
    let d = new Date(valordata);
    let dia = d.getDate() + '';
    let mes = (d.getMonth() + 1) + '';
    let ano =  d.getFullYear();

    if (dia.length==1) dia = '0'+dia;
    if (mes.length==1) mes = '0'+mes;
    valordata = dia + '/' + mes + '/' + ano;

    return valordata;
  }
}
