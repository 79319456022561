import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Estado } from 'src/app/models/estado';
import { Marca } from 'src/app/models/marca';
import {Metadado} from 'src/app/models/metadado';
import { MarcaService } from 'src/app/services/marca.service';
import {MetadadoService} from 'src/app/services/metadado.service';
import {Field} from '../../models/field';

@Component({
    selector: 'app-form-metadados',
    templateUrl: './form-metadados.component.html',
    styleUrls: ['./form-metadados.component.scss']
})
export class FormMetadadosComponent implements OnInit {
    @Output() metadadoFoiFechado = new EventEmitter();
    @Input() metadados: Metadado[];
    @Input() marcas: Marca[];
    @Input() estado: Estado;

    nomeNovoFilho: string = '';
    metadadoService: MetadadoService;
    erro: string;
    modoInsert: boolean = false;
    metadadoTemp: Metadado;
    modoEdit: boolean = false;
    nomeFoiEditado: boolean = false;
    marcaService: MarcaService;

    get paiEditando(): Metadado {
        return this.metadados.find(m => m.emEdicao);
    }

    constructor(metadadoService: MetadadoService, marcaService: MarcaService) {
        this.marcaService = marcaService;
        this.metadadoService = metadadoService;
    }

    ngOnInit(): void {
        this.metadadoTemp = new Metadado({});
    }

    abreEdit(pai: Metadado) {
        this.cancelaEdit();

        this.metadadoTemp = JSON.parse(JSON.stringify(pai));

        this.modoEdit = true;
        pai.emEdicao = true;
    }

    excluiPai(pai: Metadado) {
        if (confirm("Tem certeza que deseja excluir esse Metadado?"))
            this.metadadoService.delete(pai.id)
            .then(result => this.metadados.splice(this.metadados.indexOf(pai), 1))
            .catch(ex => {
                if (typeof ex.error === 'object' && ex.error !== null)
                    this.erro = 'Houve um erro na edição';
                else
                    this.erro = ex.error;
            });
    }

    salva() {
        if (this.modoInsert) this.incluirMetadado();
        else if (this.modoEdit) this.salvaEdit(this.metadadoTemp);
    }

    incluirMetadado() {
        this.erro = '';
        this.metadadoService.insert(this.metadadoTemp)
        .then((result : Metadado) => {
            this.metadados.push(result);
            this.modoInsert = false;

        })
        .catch(ex => {
            if (typeof ex.error === 'object' && ex.error !== null)
                this.erro = 'Houve um erro na edição';
            else
                this.erro = ex.error;
        });
    }

    salvaEdit(pai: Metadado) {
        this.erro = '';
        this.metadadoService.update(pai).then((result : Metadado) => {

            let posicao = this.metadados.map(m => m.id).indexOf(result.id);
            this.metadados[posicao] = result;

            let marcas = JSON.parse(JSON.stringify(this.marcas));
            let metadados = JSON.parse(JSON.stringify(this.metadados));

            pai.metadados.forEach(m => m.pai = result.nome);

            this.metadadoService.setMetadado(result);
            this.marcas = this.marcaService.processaMarcas(marcas, metadados);

            this.estado.filtrosSelecionados = [];

            this.cancelaEdit();
        }).catch(ex => {
            if (typeof ex.error === 'object' && ex.error !== null)
                this.erro = 'Houve um erro na edição';
            else
                this.erro = ex.error;
        });
    }

    cancela() {
        this.cancelaInsert();
        this.cancelaEdit();
    }

    cancelaEdit() {
        this.metadados.forEach(m => m.emEdicao = false);
        this.nomeNovoFilho = '';
        this.modoEdit = false;
    }

    excluirFilho(pai: Metadado, filho: Metadado) {
        let marcas = '';
        let id = filho.id;
        let encontrou = false;

        this.marcas.forEach(marca => {
            let metadados = marca.metadados;

            if (metadados)
                metadados.forEach(metadado => {
                    if (metadado == id) {
                        encontrou = true;

                        if (marcas=='')
                            marcas = marca.nome;
                        else
                            marcas += ", " + marca.nome;
                    }
            })

        });

        let mensagem = 'Ao excluir esse item você estará retirando das marcas onde ele está atualmente associado: ' + marcas;
        
        if (!encontrou || confirm(mensagem))
            pai.metadados.splice(pai.metadados.indexOf(pai.metadados.find(f => f.nome == filho.nome)), 1);
    }

    VerificaEnter(ev, pai: Metadado) {
        if (ev.keyCode === 13) {
            this.incluiFilho(pai);
        }
    }

    incluiFilho(pai: Metadado) {
        if (this.nomeNovoFilho) {
            pai.metadados.push(<Metadado> {nome: this.nomeNovoFilho});
            this.nomeNovoFilho = '';
        }
    }

    fecharDetalhe() {
        this.metadadoFoiFechado.emit('');
    }

    novoMetadado() {
        this.modoInsert = true;
        this.nomeFoiEditado = false;
        this.metadadoTemp.nome = '';
    }

    cancelaInsert() {
        this.modoInsert = false;
        this.erro = '';
    }

}
