import { Tag } from './tag';
import { Arquivo } from './arquivo';
import { Metadado } from './metadado';

export class Marca {
    id: string;
    nome: string;
    personalidade: string;
    visao: string;
    missao: string;
    tags: string[];
    arquivos: Arquivo[];
    metadados: string[];
    
    linkKit: string;
    imagem: string;
    atributosPrincipais: string;
    posicaoArquivo: number = 0;

    constructor(m: any, mt: Metadado[]) {
        if (m)
        {
            this.id = m.id;
            this.nome = m.nome;
            this.personalidade = m.personalidade;
            this.visao = m.visao;
            this.missao = m.missao;
            this.tags = m.tags;            
            this.arquivos = m.arquivos;
            this.metadados = m.metadados;
            
            this.atributosPrincipais = m.atributosPrincipais;

            if (m.arquivos) {
                let posicao = m.arquivos.filter(x => x.extensao != 'zip').map(x => x.selecionado).indexOf(true);
                this.posicaoArquivo = 0;
                if (posicao > -1) {
                    this.posicaoArquivo = posicao;
                    this.imagem = m.arquivos.filter(x => x.extensao != 'zip')[posicao].link;
                } else {
                    if (m.arquivos.filter(x => x.extensao != 'zip')[0])
                        this.imagem = m.arquivos.filter(x => x.extensao != 'zip')[0].link;
                    else
                        this.imagem = 'assets/img/semImagem.jpg';
                }
            
                let kitArray = m.arquivos.filter((x : Arquivo) => x.extensao.toLowerCase() == 'zip');
                if (kitArray.length > 0) {
                    this.linkKit = kitArray[0].link;
                }
            }
        }
    }
    //Todo: Função não utilizada 
    private convertTag(tags_: string[]) : Tag[] {
        let ret : Tag[] = [];
        tags_.forEach((tag) => {
            const item : Tag = new Tag('', tag, '');
            ret.push(item);
        });
        return ret;
    }
    //Todo: Função não utilizada
    private convertMetadados(metadados_: string[], mt: Metadado[]) : Metadado[] {
        let ret : Metadado[] = [];
        if (mt) {
            metadados_.forEach((met) => {
                mt.forEach((metadado : Metadado) => {                        
                    let metadadoEncontrado = metadado.metadados.filter(x => x.id == met);
                    if (metadadoEncontrado.length > 0) {
                        let selecionado = metadadoEncontrado[0];

                        const item : Metadado = new Metadado({
                            id: met, 
                            nome: selecionado.nome, 
                            metadados: [],
                            pai: metadado.nome,                            
                            exibe: selecionado.exibe
                        });
                        ret.push(item);
                    }
                });
            });
        }   
        return ret;
    }
}
