<div class="grupo-marcas" [ngClass]="{'grupo-marcas-aberto' : estado.gridDetalheSelecionado, 'grupo-marcas-fechado' : !estado.gridDetalheSelecionado, 'ajusteComDoisItens' : estado.quantidadeItens == 2}">
    <ng-container *ngFor="let marca of marcas; let posicao = index">
            <app-marca 
            [marca]="marca" 
            [posicao]="posicao"
            (detalheFoiAberto)="abrirDetalhe($event)" 
            [estado]="estado"
            [pointer]="true"
            class="container-marca" 
            [ngClass]="{'container-marca-aberto' : estado.gridDetalheSelecionado, 'margin-left': estado.quantidadeItens == 2 }"></app-marca>
    </ng-container>
</div>