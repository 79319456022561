import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthGuard } from './core/auth-guard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MarcaComponent } from './site/marca/marca.component';
import { GrupoMarcasComponent } from './site/grupo-marcas/grupo-marcas.component';
import { AdmComponent } from './adm/adm.component';
import { SiteComponent } from './site/site.component';
import { FormMarcaComponent } from './adm/form-marca/form-marca.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material/material.module';
import { DetalheMarcaComponent } from './site/detalhe-marca/detalhe-marca.component';
import { HeaderComponent } from './site/header/header.component';
import { FilterTopComponent } from './site/filter-top/filter-top.component';
import { MetadadosComponent } from './site/metadados/metadados.component';
import { HeaderGrupoMarcasComponent } from './site/header-grupo-marcas/header-grupo-marcas.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { DialogComponent } from './site/dialog/dialog.component';
import { GridUsuariosComponent } from './adm/grid-usuarios/grid-usuarios.component';
import { FormMetadadosComponent } from './adm/form-metadados/form-metadados.component';

@NgModule({
  declarations: [
    AppComponent,
    MarcaComponent,
    GrupoMarcasComponent,
    AdmComponent,
    SiteComponent,
    FormMarcaComponent,
    DetalheMarcaComponent,
    HeaderComponent,
    FilterTopComponent,
    MetadadosComponent,
    HeaderGrupoMarcasComponent,
    LoginComponent,
    DialogComponent,
    GridUsuariosComponent,
    FormMetadadosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    HttpClientJsonpModule,
    HttpClientModule,
    OAuthModule.forRoot() 
  ],
  providers: [ AuthGuard ] ,
  bootstrap: [ AppComponent ]
})
export class AppModule { }
