import { Component, Input, Output, EventEmitter, Inject, ElementRef } from '@angular/core';
import { Metadado } from 'src/app/models/metadado';
import { Field } from 'src/app/models/field';
import { Marca } from 'src/app/models/marca';
import { MarcaService } from 'src/app/services/marca.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/site/dialog/dialog.component';
import { Arquivo } from 'src/app/models/arquivo';
import { ViewChild } from '@angular/core';
import { FileService } from 'src/app/services/file.service';
import { Estado } from 'src/app/models/estado';

@Component({
  selector: 'app-form-marca',
  templateUrl: './form-marca.component.html',
  styleUrls: ['./form-marca.component.scss']
})
export class FormMarcaComponent {
  titleAlert: string = 'Digite o valor do campo';
  post: any = '';
  camposMarcas: Field[] = [];
  newTag: string = '';
  marca: Marca;
  mensagemErro: string;
  erroKit: string = '';
  erroCapa: string = '';
  loaderKit: boolean = false;
  loaderCapa: boolean = false;
  _capaSelecionada: string = null;

  get capaSelecionada(): string {
    return this._capaSelecionada;
  }

  set capaSelecionada(value : string) {
    if (value !== this._capaSelecionada) {
      this._capaSelecionada = value;
      
      this.marca.imagem = this.marca.arquivos.filter(a => a.id == this._capaSelecionada)[0].link;
      this.marcaRecebida.imagem = this.marca.arquivos.filter(a => a.id == this._capaSelecionada)[0].link;

      this.marca.arquivos.forEach(a => a.selecionado = false);
      this.marca.arquivos.filter(a => a.id == value)[0].selecionado = true;
    }
  } 

  @Input() marcas: Marca[];
  @Input() marcaRecebida: Marca;
  @Input() metadados : Metadado[];
  @Output() canceled = new EventEmitter();
  @Output() positionchanged = new EventEmitter();
  @Input() estado: Estado;

  constructor(private marcaService: MarcaService, 
    private fileService: FileService,
    public dialog: MatDialog) { 
      
  }

  ngOnInit() {
    if (this.marcaRecebida) {
      this.marca = JSON.parse(JSON.stringify(this.marcaRecebida));

      let capaSelecionada = this.marca.arquivos.filter((a : Arquivo) => a.extensao != 'zip' && a.selecionado);
      if (capaSelecionada[0]) {
        this.capaSelecionada = capaSelecionada[0].id;
      } else {
        if (this.capas()[0])
          this.capaSelecionada = this.capas()[0].id;
      }
    }
    else
      this.marca = new Marca({
        nome: '', 
        id: null,
        arquivos: [],
        atributosPrincipais : '',
        imagem: '',
        linkKit: '',
        metadados: [],
        missao: '',
        personalidade: '',
        tags: [],
        visao: ''
      }, []);
    
    this.configuraCampos();

    this.mensagemErro = '';


    
  }

  configuraCampos() {
    this.camposMarcas = [
      new Field('nome', 'Marca', 'input', false),
      new Field('personalidade', 'Personalidade', 'textarea', true),
      new Field('visao', 'Visão', 'textarea', false),
      new Field('missao', 'Missão', 'textarea', false),
      new Field('atributosPrincipais', 'Atributos Principais', 'textarea', true)
    ];
  }

  onSubmit() {

    let fnAcertarMetadadosdeMarcas = () => {
      while (this.marca.metadados.length > 0) this.marca.metadados.pop();
      this.metadados.forEach(m => {
        m.selecionados.forEach(s => {
          this.marca.metadados.push(s);
        });
      });
    };
    fnAcertarMetadadosdeMarcas();

    this.marcaService.save(this.marca)
    .then((dt : any) => {
      let isInclusao = !(this.marca.id);

      if (isInclusao) {
        this.marca.id = dt.id;
        this.marcas.push(JSON.parse(JSON.stringify(this.marca)));
        this.marcaService.add(JSON.parse(JSON.stringify(this.marca)));
        this.marcaService.filtrar(this.estado);

        this.positionchanged.emit(this.marca.id);
      } else {
        this.marcaService.update(this.marca);

        this.marcas.forEach((marca, index) => {
          if (marca.id === this.marca.id) {
            this.marcas[index] = this.marca;
          }
        });
      }
      
      this.fechar();
    })
    .catch(erro => {
      this.mensagemErro = erro.error;
    });
  }

  reset() {
    this.post = null;
  }

  incluirTag() {
    if (this.newTag) {
      this.marca.tags.push(this.newTag);
      this.newTag = '';
    }
  }

  selecionaMetadado(event, metadado: Metadado, pai: Metadado) {
    if (event.checked)
      pai.selecionados.push(metadado.id);
    else {
      pai.selecionados = [].concat(
        pai.selecionados.filter(m => m != metadado.id)
      );
    }

    return true;
  }

  filhoSelecionado(metadado : Metadado) {

    let retorno = false;
    this.marca.metadados.forEach((metadadoMarca : String) => {
      if (metadado.id == metadadoMarca) {
        retorno = true;
      }
    });

    return retorno;
  }

  invalido() {
    let invalido = false;

    this.camposMarcas.forEach(campo => {
      if (!campo.nullable)
        if (!this.marca[campo.name]) invalido = true;
    });
    if (this.newTag != '') invalido = true;

    return invalido;
  }

  excluirTag(posicao: number) {
    this.marca.tags.splice(posicao, 1);
  }

  excluirCapa(posicao: number) {
    //Temos que pegar a verdadeira posição tirando o item que é Kit
    this.mensagemErro = '';
    this.loaderCapa = true;
    let contador = -1;
    let posicaoCorreta = -1;
    while (contador < this.marca.arquivos.length - 1 && posicao != posicaoCorreta)
    {
      contador++;

      if (this.marca.arquivos[contador].extensao.toLowerCase() != 'zip')
        posicaoCorreta++
    }

    //Agora realizar a exclusão no back

    this.fileService.deleteFile(this.marca.arquivos[contador].id).then(
      () => {
        this.marca.arquivos.splice(contador, 1);
        this.marcaRecebida.arquivos.splice(contador, 1);
        this.loaderCapa = false;

        if (this.capas().length == 0)
        {
          this.marca.imagem = '/assets/img/semImagem.jpg';
          this.marcaRecebida.imagem = '/assets/img/semImagem.jpg';
        }
      }
    ).catch(() => {
      this.mensagemErro = 'Não foi possível excluir o arquivo.';
      this.loaderCapa = false;
    });
    

  }

  fechar() {
    this.canceled.emit(this.marca);
  }

  VerificaEnter(ev) {
    if (ev.keyCode === 13) {
      this.incluirTag();
    }
  }

  
  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Ok') this.delete();
    });
  }
  

  delete() {
    this.marcaService.delete(this.marca)
    .then((conseguiu : boolean) => {
      //Em caso de sucesso, retirar a marca do array
      if (conseguiu)
      {
        this.marcas.forEach((marca, index) => {
            if (marca.id === this.marca.id) {
              this.marcas.splice(index, 1);
            }
          });
        this.marca.id = undefined;
        this.fechar();
      }
    })
    .catch(erro => {
      this.mensagemErro = erro.error;
    });
  }

  kit() {
    return this.marca.arquivos.filter(a => a.extensao == 'zip');
  }

  capas() {
    return this.marca.arquivos.filter(a => a.extensao != 'zip');
  }

  checked(filho) {
    return false;
  }

  @ViewChild('fileCapa')
  fileCapaElement: ElementRef;

  @ViewChild('fileKit')
  fileKitElement: ElementRef;


  insertCapa(files: FileList) {
    this.erroCapa = '';
    this.loaderCapa = true;
    let arquivo : File = files[0];
    let extensao = arquivo.name.split('.')[1].toLowerCase();
    if (arquivo.size < 1048576) {
      if (extensao == 'jpg' || extensao == 'png' || extensao == 'jpeg')
      {
        this.fileService.postFile(arquivo, this.marca.id).then((result : Arquivo) => {
          result.marcaId = this.marca.id;
          this.addCapa(result);
          this.fileCapaElement.nativeElement.value = '';
          this.loaderCapa = false;          
        }).catch(e => {
          this.mensagemErro = 'Não é possível realizar o upload dessa imagem.';
          this.fileCapaElement.nativeElement.value = '';
          this.loaderCapa = false; 
        });
      }
      else
      {
        this.erroCapa = 'Selecione uma imagem jpg/jpeg ou png';
        this.loaderCapa = false;
      }
    } else {
      this.erroCapa = 'Arquivo muito grande, selecione um arquivo menor';
      this.loaderCapa = false;
    }
  }
  
  changeKit(files) {
    this.mensagemErro = '';
    this.erroKit = '';
    this.loaderKit = true;
    let arquivo : File = files[0];
    let aArquivo = arquivo.name.split('.');
    if (aArquivo[aArquivo.length-1].toLowerCase() == 'zip') {
      if (arquivo.size < 104857600) {
        this.fileService.postFile(arquivo, this.marca.id).then((result : Arquivo) => {
          result.marcaId = this.marca.id;
          this.montaKit(result); 
          this.loaderKit = false;     
          this.fileKitElement.nativeElement.value = ''; 
        }).catch(e => {
            this.mensagemErro = 'Não foi possível realizar o upload do arquivo.';
            this.fileKitElement.nativeElement.value = '';
            this.loaderKit = false; 
          }
        );
      } else {
        this.erroKit = 'É necessário escolher um arquivo com menos de 100 Megas';
        this.loaderKit = false; 
      }
    } else {
      this.erroKit = 'É necessário escolher um arquivo do tipo ZIP';
      this.loaderKit = false; 
    }
  }

  addCapa(arquivo : Arquivo) {
    this.marca.arquivos.push(arquivo);
    this.marcaRecebida.arquivos.push(arquivo);

    if (this.capas().length == 1) {
      this.capas()[0].selecionado = true;
      this.capaSelecionada = this.capas()[0].id;
    }
  }

  montaKit(arquivo: Arquivo) {
    this.excluirKit();
    this.marca.arquivos.push(arquivo);
    this.marcaRecebida.arquivos.push(arquivo);

    this.marca.linkKit = arquivo.link;
    this.marcaRecebida.linkKit = arquivo.link;
  }

  excluirKit() {
    var kit = this.marca.arquivos.filter(a => a.extensao == 'zip')[0];

    if (kit) {
      let idKit = kit.id;
      this.mensagemErro = '';
      this.loaderKit = true;
      setTimeout(() => {
        this.fileService.deleteFile(idKit).then(
          () => {
            this.marca.arquivos = [].concat(this.marca.arquivos.filter((a) => a.extensao != 'zip'));
            this.marcaRecebida.arquivos = [].concat(this.marca.arquivos.filter((a) => a.extensao != 'zip'));
            this.marca.linkKit = '';
            this.marcaRecebida.linkKit = '';
            this.loaderKit = false;
          }
        ).catch(() => {
          this.mensagemErro = 'Não foi possível excluir o arquivo.';
          this.loaderKit = false;
        });
      }, 100);
    }
  }

}

