import { Injectable } from '@angular/core';
import { Metadado } from '../models/metadado';
import { HttpService } from './http.service';
import { Marca } from '../models/marca';

@Injectable({
  providedIn: 'root'
})
export class MetadadoService {
  metadados: Metadado[] = [];
  exibe: Metadado[] = [];
  httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  setMetadado(metadado: Metadado)
  {
    let posicao = this.metadados.map(m => m.id).indexOf(metadado.id);
    this.metadados[posicao]=metadado;

    this.exibe = [];
    this.metadados.forEach((m : Metadado) => {
      m.metadados.forEach((filho : Metadado) => {
        filho.ordemExibicao = m.ordemExibicao;
        filho.pai = m.nome;
        if (m.exibe) this.exibe.push(filho);
      });
    });
  }

  metadadoExibe(marca: Marca): Metadado[] {
    let resultado = this.exibe.filter(met => {
      let ret = false;
      if (marca.metadados)
        marca.metadados.forEach(m => {
          if (met.id == m) ret = true;
        });
      return ret;
    });
    
    resultado = resultado.sort((a, b) => {
      if (!a.ordemExibicao || a.ordemExibicao==0) return 1;
      
      if (a.ordemExibicao >= b.ordemExibicao ) return 1; 
      else return -1;
    });

    return resultado;
  }

  getAll() : Promise<Metadado[]> {
    return new Promise(
      (resolve, reject) => {   
        if (this.metadados.length==0) {
          this.httpService.get("v1/Metadado/GetAll")
          .then((dt: Metadado[]) => {
            dt.forEach(data => {
              this.metadados.push(new Metadado(data));
            });

            this.metadados.forEach(m => {
              m.metadados.forEach(filho => {
                if (m.exibe) {
                  filho.ordemExibicao = m.ordemExibicao;
                  filho.exibe = m.exibe;
                  this.exibe.push(filho)
                };
              });
            });

            resolve(this.metadados);
          })
          .catch(error => reject(error))
        } else {
          resolve(this.metadados);
        }
      }
    );
  }

  insert(metadado: Metadado) {
    return this.httpService.post('v1/Metadado/Add', metadado);
  }

  update(metadado: Metadado) {
    return this.httpService.put('v1/Metadado/Update', metadado);
  }

  delete(id: string) {
    return this.httpService.delete('v1/Metadado/Delete' + '?id=' + id);
  }

}
