<div id="div-header">
    <div class="marcas-header">
      <span><img class="titulo" src="assets/img/titulo.jpg"></span>
    </div>
    <button mat-button [matMenuTriggerFor]="menu" class="marcas-logout">menu</button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="insertMarca()" *ngIf="estado.administrador">Marca</button>
      <button mat-menu-item (click)="abreUsuarios()" *ngIf="estado.administrador">Usuários</button>
      <button mat-menu-item (click)="abreMetadados()" *ngIf="estado.administrador">Metadados</button>
      <button mat-menu-item (click)="logoff()">Sair</button>
    </mat-menu>
</div>