export class Metadado {
    id: string;
    nome: string = ''; // Pai e filho
    metadados: Metadado[] = []; // Pai
    exibe: boolean = false; // Pai
    marcaId: string; 
    pai: string; 
    encontrou: boolean = false; 
    aceitaMultiplo: boolean = false; // Pai
    ordemExibicao: number = 0; // Pai
    selecionados: string[] = []; 
    filhoInclusao: string = ''; 
    nomeEditado: string = '';

    emEdicao: boolean = false;

    constructor(item: any) {
        this.id = item.id;
        this.nome = item.nome;
        this.nomeEditado = item.nome;
        this.metadados = [];
        this.pai = item.pai;
        
        if (item.metadados)
            item.metadados.forEach(
                dado => { 
                    dado.pai = item.nome;
                    this.metadados.push(new Metadado(dado)) 
                }
            );

        this.exibe = item.exibe;
       
        this.aceitaMultiplo = item.aceitaMultiplo;
        this.ordemExibicao = item.ordemExibicao;
    }
}
