import { AuthConfig } from 'angular-oauth2-oidc';
import {environment} from "../../environments/environment";

export const authConfig: AuthConfig = {
  //issuer: 'https://id.globo.com/auth/realms/globoi', //producao
  issuer: 'https://id.qa.globoi.com/auth/realms/globoi', //dev/hmg
  clientId: environment.clientId,
  redirectUri: environment.redirectUri,
  scope: 'openid email',
  requireHttps: true,
  responseType: 'code',
  showDebugInformation: true
};
