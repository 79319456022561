import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Marca } from 'src/app/models/marca';
import { MarcaService } from 'src/app/services/marca.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Metadado } from 'src/app/models/metadado';
import { MetadadoService } from 'src/app/services/metadado.service';
import { Estado } from 'src/app/models/estado';


@Component({
  selector: 'app-detalhe-marca',
  templateUrl: './detalhe-marca.component.html',
  styleUrls: ['./detalhe-marca.component.scss']
})

export class DetalheMarcaComponent implements OnInit {
  @Input() marcas : Marca[];
  @Input() metadados : Metadado[];

  cacheImagem: string;

  get marcaAtual() : Marca {
    let ret = this.marcas[this.posicao]
    if (ret)
      return ret;
    else 
      return new Marca({}, []);
  }
  
  posicao : number = 0;
  @Input() marcaId: string;

  @Output() detalheFoiFechado = new EventEmitter();

  marcaService: MarcaService;
  metadadoService: MetadadoService;
  @Input() modoEdicao: boolean;

  @Input() estado: Estado;

  //processado : boolean = false;

  cores : any = {
    'máximo': '#eb0032',
    'muito': '#e6199b',
    'médio': '#ff6f00',
    'pouco': '#8cc328',
    'muito pouco': '#00c4e6', 
    'nenhum': '#009edc'
  }

  constructor(marcaService: MarcaService,
    metadadoService: MetadadoService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.marcaService = marcaService;
    this.metadadoService = metadadoService;
  }
  
  ngOnInit(): void {
    let id : string = '0';
    id = this.marcaId;
    if (id == '-1') 
      this.posicao = -1 
    else
      this.marcas.forEach((marca, index) => {
        if (marca.id == id) {
          this.posicao = index;
        }
      });

      this.selecionaMetadados();
  }

  changePosition(id: string)
  {
    let encontrou = false;
    let i = 0;
    while (!encontrou && i < this.marcas.length)
    {
      if (this.marcas[i++].id === id)
      {
        this.posicao = i-1;
        encontrou = true;
      }
    }
  }

  canceled(m) {
    if (m.id)
      this.modoEdicao=false;
    else
      this.detalheFoiFechado.emit('');
  }

  proximo() {
    if (this.marcaAtual.posicaoArquivo < this.capas().length - 1) {
      this.marcaAtual.imagem = 'assets/img/loader.gif';
      this.marcaAtual.posicaoArquivo++;
      this.cacheImagem = this.capas()[this.marcaAtual.posicaoArquivo].link;
      setTimeout(() => {
        this.marcaAtual.imagem = this.cacheImagem;
      }, 250);
      
    }
  }

  anterior() {
    if (this.marcaAtual.posicaoArquivo > 0) {
      this.marcaAtual.imagem = 'assets/img/loader.gif';
      this.marcaAtual.posicaoArquivo--;
      this.cacheImagem = this.capas()[this.marcaAtual.posicaoArquivo].link;
      setTimeout(() => {
        this.marcaAtual.imagem = this.cacheImagem;
      }, 250);
    }
  }

  capas() {
    if (this.marcaAtual.arquivos)
      return this.marcaAtual.arquivos.filter(x => x.extensao != 'zip');
    else
      return [];
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.code == 'Escape')
      this.fecharDetalhe();
  }

  fecharDetalhe() {
    this.detalheFoiFechado.emit('');
  }

  selecionaMetadados() {
      this.metadados.forEach((metadado: Metadado) => {
        metadado.selecionados = [];
        if (this.marcaAtual)
          metadado.metadados.forEach(m => {
            if (this.marcaAtual.metadados) {
              let filtrado = this.marcaAtual.metadados.filter(met => met == m.id);
              if (filtrado[0])
                metadado.selecionados.push(filtrado[0]);
            }
          });
    });
  }
  

}
