import { Component, OnInit } from '@angular/core';
import { MarcaService } from '../services/marca.service';
import { Marca } from '../models/marca';
import { Metadado } from '../models/metadado';
import { Estado } from '../models/estado';
import { MetadadoService } from '../services/metadado.service';
import { Usuario } from '../models/usuario';
import { HttpService } from '../services/http.service';


@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {

  title = 'globomarcas';
  marcas : Marca[];
  marcasCompletas: Marca[];
  aberto: boolean = true;
  marcaId: number;
  estado : Estado = {
    gridDetalheSelecionado: false, 
    quantidadeItens: 0,
    filtro: '',
    filtrosSelecionados : [],
    filtroaberto: true,
    detalheMarcaVisible: false, 
    administrador: false,
    usuariosVisible: false,
    metadadosVisible: false
   };
  metadados: Metadado[] = [];
  usuario: Usuario;

  modoEdicao: boolean = false;

   debug: any = {};

  constructor(private marcaService: MarcaService, 
    private metadadoService: MetadadoService,
    private httpService: HttpService
  ) {

    this.estado.administrador = JSON.parse(localStorage.getItem("usuario"))['acessoAdministrativo'];

  }

  ngOnInit() {
    this.marcas = [];
    let usuario = localStorage.getItem('usuario');
    if (usuario) {
      let token = JSON.parse(usuario).token;
      
      this.httpService.setToken(token);
    }
    this.metadadoService.getAll().then((data : Metadado[]) => { 
      this.metadados = data;
    
      this.marcaService.getAll(this.metadados).then(data => {
        this.marcas = data;
        this.marcasCompletas = this.marcaService.getMarcasCompletas();
      });
    }).catch(err => {
      location.href = location.href.split('/')[0];
    });
    
  }

  incluiFiltro(filtro) {
    let temp : Metadado[] = [];
    if (filtro.selecionado)
      this.estado.filtrosSelecionados.push(filtro);
    else
    {
      this.estado.filtrosSelecionados.forEach(el => {
        if (el.id != filtro.id)
          temp.push(el);
      });
      while (this.estado.filtrosSelecionados.length > 0) this.estado.filtrosSelecionados.pop();
      temp.forEach(el => this.estado.filtrosSelecionados.push(el));
    }
    this.filtrar(null);
  }

  abertofechado(aberto) {
    this.aberto = !aberto;

    this.estado.filtroaberto = this.aberto;
  }

  abrirDetalhe(id) {
    this.marcaId = id;
    this.estado.detalheMarcaVisible = true;
  }

  removeFiltro(filtro) {
    filtro.selecionado = false;
    this.incluiFiltro(filtro);
  }

  filtrar(event) {
    this.marcaService.filtrar(this.estado);
  }

  insertMarca() {
    this.marcaId = -1;
    this.modoEdicao = true;
    this.estado.detalheMarcaVisible = true;
  }

  abreUsuarios() {
    this.estado.usuariosVisible = true;
  }

  abreMetadados() {
    this.estado.metadadosVisible = true;
  }

  fechaUsuarios() {
    this.estado.usuariosVisible = false;
  }

  fechaMetados() {
    this.marcas = this.marcaService.updateMarcas();

    this.estado.metadadosVisible = false;
  }
}
