<div class="square">
       <div class="detalhe" *ngIf="estado.gridDetalheSelecionado && marca.nome">
              <div class="detalhe-titulo">{{ marca.nome }}</div>
              <ng-container  *ngIf="metadadoService.metadadoExibe(marca)[0]">
                     <div class="detalhe-metadado">{{metadadoService.metadadoExibe(marca)[0].pai}}</div>
                     <div class="tag">{{metadadoService.metadadoExibe(marca)[0].nome}}</div>
              </ng-container>
              <div class="tag">
                     <ul>
                            <li *ngFor="let tag of marca.tags">{{ tag }}</li>
                     </ul>
              </div>
              <div class="detalheMarca_Head_Baixar">
                     <img src="assets/img/file.png" />
                     <a [href]="marca.linkKit" class="linkKit">
                            Download
                     </a>
              </div>
       </div>
       
       <div class="block" 
              (click)="abrirDetalhe(marca.id)"
              [ngClass]="{'block-fechado' : !estado.gridDetalheSelecionado, 'block-pointer': pointer}" >

              <img *ngIf="marca.nome" class="marca-img" [src]="imagem()">
       </div>
</div>
