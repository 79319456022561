<div id="div-background" (click)="fecharDetalhe()">
    <div id="botaofechar" (click)="fecharDetalhe()">
      <img src="assets/img/close-cinza.png">
    </div>
</div>

<div id="gridUsuarios">
  <div class="linha title">
    Cadastro de Administradores
  </div>
  
  <div class="linha inclusao">
    <mat-form-field class="form-element" appearance="legacy" >
        <input matInput [(ngModel)]="nomeInclusao" placeholder="Nome"/>
    </mat-form-field>
    <mat-form-field class="form-element emailInclusao" appearance="legacy" >
        <input matInput [(ngModel)]="emailInclusao" type="email" placeholder="Email"/>
    </mat-form-field>
    <a class="botao botaoincluir" (click)="incluir()">Incluir</a> 
  </div>
  <div class="linha erro">
    {{ erro }}
  </div>
  <div class="linha containertabela">
    <table class="gridUsuario mat-table cdk-table mat-elevation-z8">
        <thead>
          <tr class="titulotabela mat-header-row cdk-header-row ng-star-inserted">
            <th class="nometitulo">Nome</th>
            <th class="emailtitulo">Email</th>
            <th class="acaotitulo">Ações</th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <tr role="row" class="mat-row cdk-row ng-star-inserted" *ngFor="let usuario of usuarios">
            <td role="gridcell" class="celula mat-cell cdk-cell cdk-column-position mat-column-position ng-star-inserted">
              <mat-label *ngIf="!usuario.edicao">{{ usuario.nome }}</mat-label>
              <mat-form-field *ngIf="usuario.edicao" class="form-element" appearance="legacy" required>
                <input matInput [(ngModel)]="nomeEdicao" placeholder="Nome"/>
              </mat-form-field> 
              <div class="erro">{{ usuario.erroNome }}</div>

            </td>
            <td role="gridcell" class="celula mat-cell cdk-cell cdk-column-position mat-column-position ng-star-inserted">
              <mat-label *ngIf="!usuario.edicao">{{ usuario.email }}</mat-label>

              <mat-form-field *ngIf="usuario.edicao" class="form-element" appearance="legacy" required>
                <input matInput [(ngModel)]="emailEdicao" placeholder="Email" type="email"/>
              </mat-form-field> 
              <div class="erro">{{ usuario.erroEmail }}</div>

            </td>
            <td role="gridcell" class="celula mat-cell cdk-cell cdk-column-position mat-column-position ng-star-inserted">
              <mat-icon *ngIf="!usuario.edicao" aria-hidden="false" aria-label="Editar" (click)="editar(usuario)" class="icon">edit</mat-icon> 
              <mat-icon *ngIf="!usuario.edicao" aria-hidden="false" aria-label="Excluir" (click)="excluir(usuario)" class="icon">delete</mat-icon> 

              <mat-icon *ngIf="usuario.edicao" aria-hidden="false" aria-label="Editar" (click)="confirmaEdicao(usuario)" class="icon">done</mat-icon> 
              <mat-icon *ngIf="usuario.edicao" aria-hidden="false" aria-label="Cancelar" (click)="cancelar(usuario)" class="icon">clear</mat-icon> 
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</div>