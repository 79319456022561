import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { Usuario } from '../models/usuario';
import {  OAuthService } from 'angular-oauth2-oidc';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  showSpinner: boolean = false;
  erro: boolean = false;
  isAuthenticated: Observable<boolean>;
  isDoneLoading: Observable<boolean>;
  canActivateProtectedRoutes: Observable<boolean>;
  code: string;

  autenticado: boolean = false;


  constructor(private oauthService: OAuthService, private loginService: LoginService, private router: Router)  { 
    localStorage.clear();
    setTimeout(x => {
      if (this.isLogged()) this.autenticate();
      else this.login();
    }, 100);
  }

  public login() {
    this.oauthService.initLoginFlow();
  }

  tente() {
    location.href = location.href.split('/')[0];
  }

  public isLogged() {
    let ret = false;
    let url = location.href;
    let posicao = url.indexOf('code');
    if (posicao>-1) {
      this.code = url.split('?')[1].split('&')[2].split('=')[1];
      ret = true;
    }

    return ret;
  }

  public logoff() {
    this.oauthService.logOut();
  }

  public get authorizationcode() {
    return this.code;
  }
 

  ngOnInit() {
    
  }

  autenticate() : void {
    this.showSpinner = true;
    this.loginService.login(this.authorizationcode, this.oauthService['_storage']['PKCI_verifier'])
    .then((usuario: Usuario) => {
      localStorage.setItem("usuario", JSON.stringify(usuario));
      this.showSpinner = false;
      this.router.navigate(["site"]);
    }).catch((error) => 
      {
        this.showSpinner = false; 
        this.erro = true;
      });
  }
  
}
