import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MarcaService } from 'src/app/services/marca.service';
import { Estado } from 'src/app/models/estado';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';



@Component({
  selector: 'app-filter-top',
  templateUrl: './filter-top.component.html',
  styleUrls: ['./filter-top.component.scss']
})
export class FilterTopComponent implements OnInit {
  filter: string = '';
  @Input() estado : Estado;
  @Output() filtroAlterado = new EventEmitter();
  time: any = undefined;
  estadoFiltro : string = 'movel';

  constructor(private marcaService: MarcaService) { }

  ngOnInit(): void {
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    if (this.time != undefined)
      clearTimeout(this.time);

    this.time = setTimeout(() => { this.filtrar(); this.time = undefined }, 700);    
  }

  filtrar() : void {  
    this.estado.filtro = this.filter;
    this.filtroAlterado.emit(this.estado);
  }

  limpar() : void {
    this.filter = '';
    this.filtrar();
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  scrollFilter(event) : void {
    const posicao = 121;

    if (window.pageYOffset > posicao && this.estadoFiltro == 'movel')
      this.estadoFiltro = 'fixo';

    if (window.pageYOffset <= posicao && this.estadoFiltro == 'fixo')
      this.estadoFiltro = 'movel'

  }
}
