import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  httpService: HttpService;

  constructor(httpService : HttpService) { 
    this.httpService = httpService;
  }

  getAll() : Promise<Usuario[]> {
    return new Promise(
      (resolve, reject) => {
        this.httpService.get("v1/Usuario/GetAll")
        .then((dt : Usuario[]) => {
          resolve(dt);
        })
        .catch(error => reject(error))
      }
    );
  }
  
  insert(usuario: Usuario): Promise<Usuario> {
    return new Promise((resolve, reject) => {
      this.httpService.post("v1/Usuario/Add", usuario)
      .then((usuario : Usuario) => resolve(usuario))
      .catch((error) => reject(error));
    });
  }

  update(usuario: Usuario): Promise<Usuario> {
    return new Promise((resolve, reject) => {
      this.httpService.put("v1/Usuario/Update", usuario)
      .then((usuario : Usuario) => resolve(usuario))
      .catch((error) => reject(error));
    });
  }

  delete(id: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpService.delete('v1/Usuario/Delete' + '?id=' + id)
      .then((result: boolean) => resolve(result))
      .catch((error) => reject(error));
    });
  }


}
