export class Field {
    name: string = '';
    title: string = '';
    type: string = 'input';
    nullable: boolean = false;

    constructor(name: string, title: string, type: string, nullable: boolean) { 
        this.name = name;
        this.title = title;
        this.type = type;
        this.nullable = nullable;
    }
}
