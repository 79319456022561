import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Metadado } from 'src/app/models/metadado';
import { Estado } from 'src/app/models/estado';
import { Marca } from 'src/app/models/marca';

@Component({
  selector: 'app-grupo-marcas',
  templateUrl: './grupo-marcas.component.html',
  styleUrls: ['./grupo-marcas.component.scss']
})
export class GrupoMarcasComponent implements OnInit {

  @Input() marcas : Marca[];
  @Input() nome: string;
  @Input() filtros: Metadado[];
  @Output() detalheFoiAberto = new EventEmitter();
  
  @Input() estado : Estado;
  
  gridDetalheSelecionado : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  

  abrirDetalhe(id) {
    this.detalheFoiAberto.emit(id);
  }

  

}
