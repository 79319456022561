import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Usuario } from 'src/app/models/usuario';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-grid-usuarios',
  templateUrl: './grid-usuarios.component.html',
  styleUrls: ['./grid-usuarios.component.scss']
})
export class GridUsuariosComponent implements OnInit {
  @Output() usuarioFoiFechado = new EventEmitter();
 
  usuarioService: UsuarioService;
  usuarios: Usuario[];
  nomeInclusao: string;
  emailInclusao: string;

  modoInclusao: boolean;
  modoEdicao: boolean;
  nomeEdicao: string;
  emailEdicao: string;
  usuarioSelecionado: Usuario;

  erro: string = '';

  constructor(usuarioService: UsuarioService ) {
    this.usuarioService = usuarioService;
  }

  ngOnInit(): void {
    this.usuarioService.getAll().then((usuarios: Usuario[]) => {
      this.usuarios = usuarios;
    }).catch(e => {
      this.erro = 'Não foi possível carregar os dados. Tente realizar o login novamente.';
    });
  }

  validaDados(nome, email) : boolean {
    this.erro = '';
    if (!nome || !email) {
      this.erro = 'Por favor preencha o nome e o email';

      return false;
    } else {
      if (!this.isEmail(email)) {
        this.erro = 'Email inválido';

        return false;
      }
    }

    return true;
  }

  incluir(): void {
    if (!this.validaDados(this.nomeInclusao, this.emailInclusao)) {
      return;
    } 

    let usuario = new Usuario();
    usuario.nome = this.nomeInclusao;
    usuario.email = this.emailInclusao;
    
    this.usuarioService.insert(usuario).then((u) => {
      this.usuarios.push(u);
      this.nomeInclusao = '';
      this.emailInclusao = '';
      this.modoInclusao = false;
    }).catch(response => this.erro = response.error);

  }

  fecharDetalhe() {
    this.usuarioFoiFechado.emit('');
  }

  editar(e : Usuario) {
    this.usuarios.forEach(usuario => this.cancelar(usuario));


    this.modoInclusao = false;
    e.erroNome = '';
    e.erroEmail = '';
    this.nomeEdicao = e.nome;
    this.emailEdicao = e.email;
    this.usuarioSelecionado = e;
    e.edicao = true;

    this.erro = '';
  }

  confirmaEdicao(usuario: Usuario) {
    usuario.erroNome = '';
    usuario.erroEmail = '';

    let isValid = true;
    if (!this.nomeEdicao)
    {
      usuario.erroNome = 'Preencha o Nome';
      isValid = false;
    }
    if (!this.emailEdicao)
    {
      usuario.erroEmail = 'Preencha o Email';
      isValid = false;
    } else {
      if (!this.isEmail(this.emailEdicao)) {
        usuario.erroEmail = 'Email inválido';
        isValid = false;
      }
    }

    if (!isValid) return;


    let user : Usuario;
    user = {
      id : this.usuarioSelecionado.id,
      nome: this.nomeEdicao,
      email: this.emailEdicao, 
      edicao: undefined, 
      erroNome : '',
      erroEmail: ''
    }
    this.usuarioService.update(user).then(() => {
      this.usuarioSelecionado.nome = this.nomeEdicao;
      this.usuarioSelecionado.email = this.emailEdicao;
      usuario.edicao = false;
    }).catch((response) => {
      this.erro = response.error;
    })
  }

  cancelar(usuario: Usuario) {
    usuario.edicao = false;
    usuario.erroNome = '';
    usuario.erroEmail = '';
  }

  excluir(e) {
    if (confirm('Deseja excluir o usuário ' + e.nome + '?'))
    {
      this.usuarioService.delete(e.id)
      .then(() => {
          let posicao = this.usuarios.map(u => u.id).indexOf(e.id);
          this.usuarios.splice(posicao, 1);
        }
      )
      .catch(response => this.erro = response.error);
    }
  }

  isEmail(search:string):boolean
    {
        let  serchfind:boolean;
        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        serchfind = regexp.test(search);
        return serchfind
    }
}
