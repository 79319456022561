import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Arquivo } from '../models/arquivo';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpClient: HttpService) { }

  postFile(fileToUpload: File, marcaId: string) {
    const endpoint = 'v1/Arquivo/Upload';
    const formData: FormData = new FormData();
    formData.append(marcaId, fileToUpload, fileToUpload.name);
    return this.httpClient
      .post(endpoint, formData, true)
      .then((result : Arquivo) => { return result; })
      .catch((e) => { return false; });
  }

  deleteFile(id: string) {
    const endpoint = 'v1/Arquivo/DeleteFile?arquivoId=' + id;
    return this.httpClient.delete(endpoint);
  }

}
