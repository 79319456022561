import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Estado } from 'src/app/models/estado';
import { MarcaService } from 'src/app/services/marca.service';
import { Marca } from 'src/app/models/marca';
import { MetadadoService } from 'src/app/services/metadado.service';


@Component({
  selector: 'app-marca',
  templateUrl: './marca.component.html',
  styleUrls: ['./marca.component.scss']
})
export class MarcaComponent implements OnInit {
  @Input() marca: Marca;
  @Output() detalheFoiAberto = new EventEmitter();
  @Input() estado : Estado = {
    gridDetalheSelecionado : false, 
    quantidadeItens: 0,
    filtro: '',
    filtrosSelecionados: [],
    filtroaberto: false, 
    detalheMarcaVisible: false,
    administrador: false, 
    usuariosVisible: false,
    metadadosVisible: false
  };
  @Input() pointer: boolean = false;
  marcaService: MarcaService;
  metadadoService: MetadadoService;
  @Input() posicao: number = 0;

  constructor(marcaService: MarcaService, metadadoService: MetadadoService) { 
    this.marcaService = marcaService;
    this.metadadoService = metadadoService;
  }

  ngOnInit(): void {
  }

  abrirDetalhe(id) {
    if (this.marca.nome)
      this.detalheFoiAberto.emit(id);
  }

  imagem() {
    if (this.marca.imagem) return this.marca.imagem;
    else if (this.marca.nome) return '/assets/img/semImagem.jpg';
  }
}
