<div id="principal">
    <div id="login">
        <div class="card">
            <div class="tituloEntrar">
                <span class="tituloEntrarTexto">ENTRAR</span>
            </div>
            <div class="divCorpo">

                <button class="btnEntrar" color="primary" (click)="tente()">Clique para logar novamente</button>

                <span class="erro">
                    Seu logout foi realizado com sucesso.
                </span>
            </div>
            
            
        </div>
    </div>
</div>