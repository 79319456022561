import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Estado } from 'src/app/models/estado';
import { Metadado } from 'src/app/models/metadado';


@Component({
  selector: 'app-metadados',
  templateUrl: './metadados.component.html',
  styleUrls: ['./metadados.component.scss']
})
export class MetadadosComponent implements OnInit {
  @Input() filtros : Metadado[] = [];
  @Input() estado: Estado;
  
  @Output() filterClicked = new EventEmitter();
  @Output() filtroaberto = new EventEmitter();
  aberto: boolean = false;
  metadadoIncluir: string; 

  constructor() { 
  }

  ngOnInit(): void {
    
  }

  seleciona(metadado) : void {
    metadado.selecionado = !metadado.selecionado;
    this.filterClicked.emit(metadado);
  }

  abrirfechar() : void {
    this.aberto = !this.aberto;
    this.filtroaberto.emit(this.aberto);
  }

  selecionaFiltro(filtro) : void {
    filtro.selecionado = !filtro.selecionado;
  }
  
}
