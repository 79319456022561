export class Tag {
    id: string;
    nome: string;
    marcaId: string;

    constructor(id: string, nome: string, marcaId: string) {
        this.id = id;
        this.nome = nome;
        this.marcaId = marcaId;
    }
}
